import React, {useEffect, useState} from 'react';
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import arrow from "../../../../assets/icons/arrow-left.svg";

import Button from "../../../../components/Button/Button";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {getOccupation, storeOccupation} from "../../../../services/services";
import {BarLoader} from "react-spinners";
import {IOccupation, IPostOccupations} from "../../../../ts/interfaces/Occupation/interface";
import Seo from "../../../../components/Seo/Seo";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import {TypeSeo} from "../../../../Enums/TypeSeo";

const CreateOccupation: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([])

    const [params, setParams] = useState<IPostOccupations>({
        id: '',
        name: '',
        seo_pages: seoPages
    })

    const queryParams = useParams();

    const {data, isLoading} = useQuery<{
        data: IOccupation
    }>(['occupation', queryParams?.id], () => getOccupation(parseInt(queryParams?.id ?? '0')), {
        staleTime: Infinity,
        enabled: !!queryParams?.id
    })

    useEffect(() => {
        if (data && data.data) {
            setParams({
                ...params,
                id: data.data.id,
                name: data.data.name,
            })
        }
    }, [data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({...params, [e.target.name]: e.target.value})
    }

    const onSubmit = () => {
        if (!params.name || params.name.trim() === '') {
            toast.error('Preencha o nome')
            return
        }

        mutate.mutate(params)
    }

    const mutate = useMutation((data: IPostOccupations) => {
        return storeOccupation(data);
    }, {
        onSettled: (response) => {
            if (response?.data?.success) {
                toast.success('Salvo com sucesso!')
                setTimeout(() => {
                    window.location.href = '/occupations'
                }, 800)
            } else {
                toast.error('Erro ao salvar cargo')
            }
        },
        onError: (error) => {
            toast.error('Erro ao salvar cargo')
        }
    })

    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages
        })
    }, [seoPages]);

    return (
        <>
            <Formik initialValues={{name: '', acronym: ''}}
                    onSubmit={() => onSubmit()}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a href='/occupations' className={styles.btnReturn}><img src={arrow} alt="Voltar"/> Voltar</a>
                            <h1>{params.id ? 'Editar' : 'Cadastrar'} Cargo</h1>
                        </div>
                        {isLoading ?
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                            :
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>Informe os dados do Cargo</p>
                                    <div className={styles.groupData}>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">Nome</label>
                                                <input disabled={mutate.isLoading}
                                                       className={styles.input}
                                                       value={params.name}
                                                       onChange={(e) => handleChange(e)}
                                                       type="text"
                                                       name="name"
                                                       id="name"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className={styles.box}>
                                    <Seo
                                        entity_id={queryParams?.id ?? null}
                                        type={TypeSeo.OCCUPATION}
                                        seoPages={seoPages}
                                        setSeoPages={setSeoPages}
                                    />
                                </div>
                                <br></br>
                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button disabled={mutate.isLoading || mutate.isSuccess} width="175px"
                                                height="50px"
                                                type="submit">Salvar</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreateOccupation;
