import React, {useEffect} from 'react';
import styles from "./styles.module.scss";

import {IPostSeo} from "../../ts/interfaces/Seo/interface";
import {useQuery} from "react-query";
import {getSeos} from "../../services/services";
import {BarLoader} from "react-spinners";

interface SeoProps {
    entity_id: string | null;
    type: string;
    seoPages: IPostSeo[];
    setSeoPages: (data: IPostSeo[]) => void;
}

const Seo: React.FC<SeoProps> = ({entity_id, type, seoPages, setSeoPages}) => {

    const {data, isLoading} = useQuery<{
        data: IPostSeo[]
    }>(['seo', type, entity_id], () => getSeos({
        entity_id: entity_id,
        type: type
    }), {
        staleTime: Infinity,
        // enabled: !!entity_id
    })

    useEffect(() => {
        if (data && data.data) {
            setSeoPages(data.data);
        }
        console.log(data)
    }, [data, setSeoPages])

    const handleChange = (name: number, field: string, value: string) => {

        const newSeoPages = seoPages.map((seo, index) => {
            if (index === name) {
                return {
                    ...seo,
                    [field]: value
                }
            }
            return seo
        })
        setSeoPages(newSeoPages)
    }

    if (isLoading) {
        return <div className={styles.alignCenter}><span>Carregando dados SEO...</span> <BarLoader
            color={"#2b6cded9"}
            loading={isLoading}
            aria-label="Loading Spinner"
            data-testid="loader"
        /></div>
    }

    return (
        <div className={styles.container}>
            {seoPages && seoPages.map((seoPage, index) =>
                <div key={index} className={styles.box}>
                    <p className={styles.boxTitle}>Dados SEO ({seoPage.system_name})</p>
                    <div className={styles.groupData}>
                        <div className={styles.groupInput}>
                            <label>Url</label>
                            <input
                                value={seoPage.url}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                className={styles.input}
                                type="text"
                                name="url"/>
                        </div>
                        <div className={styles.groupInput}>
                            <label>H1</label>
                            <input
                                value={seoPage.h1}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                className={styles.input}
                                type="text"
                                name="h1"/>
                        </div>
                        <div className={styles.groupInput}>
                            <label>Descrição</label>
                            <textarea
                                value={seoPage.description}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                className={styles.input}
                                name="description"/>
                        </div>
                        <div className={styles.groupInput}>
                            <label>Título</label>
                            <input
                                value={seoPage.title}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                className={styles.input}
                                type="text"
                                name="title"/>
                        </div>
                        <div className={styles.groupInput}>
                            <label>Meta Descrição</label>
                            <input
                                value={seoPage.meta_description}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                className={styles.input}
                                type="text"
                                name="meta_description"/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Seo;

