import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import {
    IGetFilterPublicTendersExaminingBoards,
    IGetFilterPublicTendersInstitutions,
    IGetPublicTenders,
    IPublicTenderResponse,
} from "../../../ts/interfaces/PublicTender/interface";
import styles from "../../../styles/PublicTender/styles.module.scss";
import Button from "../../../components/Button/Button";

import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";

import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
    deletePublicTender,
    filterExaminingBoards,
    filterInstitutions,
    getPublicTenders,   
    serializeArray,
} from "../../../services/services";
import Pagination from "../../../components/Pagination/Pagination";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../services/auth";
import Select from "../../../components/Select/Select";
import {ReactComponent as File} from "../../../assets/icons/file.svg";

const PublicTender: React.FC = () => {

    const [filter, setFilter] = useState<IGetPublicTenders>({
        name: "",
        year: "",
        level: "",
        institution_id: "",
        examining_board_id: "",
        page: 1,
        limit: 50,
    });

    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const [selectFilters, setSelectFilters] = useState({
        year: "",
        institution_id: "",
        examining_board_id: "",
    });

    const navigate = useNavigate();

    const { data, isLoading } = useQuery<IPublicTenderResponse>(
        ["public-tenders", filter],
        () => getPublicTenders(filter),
        {
            staleTime: Infinity,
        }
    );

    const handleDownloadCSV = () => {
        let link = document.getElementById("download_csv");
        link?.click();
    };

    const mutateDeleteInstitution = useMutation(
        () => {
            return deletePublicTender(id as number);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["public-tenders", filter]);
                toast.success('Deletado com sucesso');
                setOpen(false);
            },
            onError: () => {
                toast.error("Erro ao deletar o concurso");
            },
        }
    );

    const getYears = () => {
        let years = [];
        let actualYear = new Date().getFullYear();
        for (let i = actualYear; i >= actualYear- 20; i--) {
            years.push(i);
        }

        return years
    };

    const {data:examiningBoards} = useQuery<IGetFilterPublicTendersExaminingBoards>('filter-examining-boards', () => filterExaminingBoards())
    
    const {data:institutions} = useQuery<IGetFilterPublicTendersInstitutions>('filter-institutions', () => filterInstitutions())

    return (
        <div className={styles.container}>
            <h1>Concursos</h1>

            <Formik
                initialValues={{
                    name: "",
                    level: "",
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        name: values.name,
                        year: selectFilters.year,
                        level: values.level,
                        institution_id: selectFilters.institution_id,
                        examining_board_id: selectFilters.examining_board_id,
                        page: 1,
                    });
                }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Pesquisar por nome"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage
                            name="name"
                            component="div"
                            className={styles.error}
                        />
                       
                        <Select
                            value={selectFilters.year}
                            onChange={(e) => {setSelectFilters({...selectFilters, year: e.target.value})}}
                        >
                            
                            <option value="">Ano</option>
                            {getYears().map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </Select>
                        <Field
                            name="level"
                            type="text"
                            placeholder="Pesquisar por nível"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage
                            name="level"
                            component="div"
                            className={styles.error}
                        />
                        <ErrorMessage
                            name="institution_id"
                            component="div"
                            className={styles.error}
                        />
                        <Select
                            value={selectFilters.institution_id}
                            onChange={(e) => {setSelectFilters({...selectFilters, institution_id: e.target.value})}}
                        >
                            
                            <option value="">Instituição</option>
                            {institutions && institutions?.data.institutions.map((institution) => (
                                <option key={institution.id} value={institution.id}>{institution.acronym}</option>
                            ))}
                           
                        </Select>
                        <ErrorMessage
                            name="examining_board_id"
                            component="div"
                            className={styles.error}
                        />
                        <Select
                            value={selectFilters.examining_board_id}
                            onChange={(e) => {setSelectFilters({...selectFilters, examining_board_id: e.target.value})}}
                        >
                            
                            <option value="">Banca Examinadora</option>
                            {examiningBoards && examiningBoards?.data.examiningBoards.map((examiningBoard) => (
                                <option key={examiningBoard.id} value={examiningBoard.id}>{examiningBoard.acronym}</option>
                            ))}
                           
                        </Select>
                        
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download />}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >
                    CSV
                </Button>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => navigate("/public-tender/create")}
                    disabled={isLoading}
                >
                    Cadastrar
                </Button>
            </div>
            <div className={styles.dNone}>
                <a
                    id="download_csv"
                    rel="noopener noreferrer"
                    href={
                        process.env.REACT_APP_API_URL +
                        "/v1/public-tender/export?" +
                        serializeArray(filter) +
                        "&token=" +
                        getToken()
                    }
                    target="_blank"
                    download
                ></a>
            </div>

            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    <>
                        {data && data.data.publicTenders.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Edital</th>
                                        <th>Número Edital</th>
                                        <th>Ano</th>
                                        <th>Nível</th>
                                        <th>Instituição</th>
                                        <th>Banca</th>
                                        <th>Dt Cadastro</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.data.publicTenders.map(
                                        (publicTender) => (
                                            <tr key={publicTender.id}>
                                                <td>{publicTender.name}</td>
                                                <td>
                                                    <a className={styles.iconButton} href={publicTender.url_notice}><File /></a>
                                                </td>
                                                <td>
                                                    {publicTender.notice_number}
                                                </td>
                                                <td>{publicTender.year}</td>
                                                <td>{publicTender.level}</td>
                                                <td>
                                                    {publicTender.institution}
                                                </td>
                                                <td>
                                                    {
                                                        publicTender.examining_board
                                                    }
                                                </td>
                                                <td>
                                                    {publicTender.created_at}
                                                </td>
                                                <td>
                                                    <button
                                                        className={
                                                            styles.editProducts
                                                        }
                                                        onClick={() =>
                                                            navigate(
                                                                `/public-tender/create/${publicTender.id}`
                                                            )
                                                        }
                                                    >
                                                        <Pencil />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => {
                                                            setId(
                                                              publicTender.id
                                                            );
                                                            setOpen(true);
                                                        }}
                                                        className={
                                                            styles.editProducts
                                                        }
                                                    >
                                                        <Trash />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <div className={styles.alignCenter}>
                                <span>Nenhum registro encontrado.</span>
                            </div>
                        )}
                    </>
                )}
            </div>
            {data && (
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) =>
                        setFilter({ ...filter, page: payload })
                    }
                />
            )}

            {open && id && (
                <ModalConfirm
                    propsFunction={mutateDeleteInstitution.mutate}
                    open={open}
                    setOpen={setOpen}
                    loading={mutateDeleteInstitution.isLoading}
                >
                    <span>Essa ação é irreversível!</span>
                    <span>Tem certeza que deseja deletar o concurso?</span>
                </ModalConfirm>
            )}
        </div>
    );
};

export default PublicTender;
