import React, { useEffect, useState } from "react";
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-left.svg";

import Select from "react-select";

import {
    IGetFilterPublicTendersCareers,
    IGetFilterPublicTendersExaminingBoards,
    IGetFilterPublicTendersInstitutions,
    IGetFilterPublicTendersOccupations,
    IGetFilterPublicTendersStates,
    IPostPublicTenders,
    IPublicTender,
} from "../../../../ts/interfaces/PublicTender/interface";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
    filterCareers,
    filterExaminingBoards,
    filterInstitutions,
    filterOccupations,
    getPublicTender,
    getStates,
    storePublicTender,
} from "../../../../services/services";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { BarLoader } from "react-spinners";
import Button from "../../../../components/Button/Button";
import { ReactComponent as Upload } from "../../../../assets/icons/upload.svg";
import Seo from "../../../../components/Seo/Seo";
import { IPostSeo } from "../../../../ts/interfaces/Seo/interface";
import { TypeSeo } from "../../../../Enums/TypeSeo";
import { IPostExam } from "../../../../ts/interfaces/Exam/interface";
import Exam from "../../../../components/Exam/Exam";

const CreatePublicTender: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([]);
    const [exams, setExams] = useState<IPostExam[]>([]);

    const [params, setParams] = useState<IPostPublicTenders>({
        id: "",
        name: "",
        url_notice: null,
        notice_number: "",
        year: 0,
        level: "",
        status: "em andamento",
        institution_id: 0,
        examining_board_id: 0,
        registration_date_start: "",
        registration_date_end: "",
        test_date: "",
        vacancies: 0,
        minimum_salary: 0,
        states: [],
        careers: [],
        occupations: [],
        maximum_salary: 0,
        tax_value: 0,
        taf: false,
        discursive_writing: false,
        proof_of_titles: false,
        seo_pages: seoPages,
        exams: exams,
    });

    const [fileName, setFileName] = useState<string>("");

    const stylesSelect = {
        control: (styles: any) => ({
            ...styles,
            width: "100%",
            height: "37px",
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            height: "37px",
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            height: "37px",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            padding: "4px",
        }),
        clearIndicator: (styles: any) => ({
            ...styles,
            padding: "4px",
        }),
    };

    const queryParams = useParams();

    const { data, isLoading } = useQuery<{
        data: IPublicTender;
    }>(
        ["public-tenders", queryParams?.id],
        () => getPublicTender(parseInt(queryParams?.id ?? "0")),
        {
            staleTime: Infinity,
            enabled: !!queryParams?.id,
        }
    );

    const { data: examiningBoards } =
        useQuery<IGetFilterPublicTendersExaminingBoards>(
            "filter-examining-boards",
            () => filterExaminingBoards()
        );

    const { data: institutions } =
        useQuery<IGetFilterPublicTendersInstitutions>(
            "filter-institutions",
            () => filterInstitutions()
        );

    const { data: states } = useQuery<IGetFilterPublicTendersStates>(
        "filter-states",
        () => getStates({ name: "", acronym: "" })
    );

    const { data: occupations } = useQuery<IGetFilterPublicTendersOccupations>(
        "filter-occupations",
        () => filterOccupations()
    );

    const { data: careers } = useQuery<IGetFilterPublicTendersCareers>(
        "filter-careers",
        () => filterCareers()
    );

    useEffect(() => {
        if (data && data.data) {
            setParams({
                ...params,
                id: data.data.id,
                name: data.data.name,
                url_notice: data.data.url_notice,
                year: data.data.year,
                notice_number: data.data.notice_number,
                level: data.data.level,
                institution_id: data.data.institution_id,
                examining_board_id: data.data.examining_board_id,
                registration_date_start: data.data.registration_date_start,
                registration_date_end: data.data.registration_date_end,
                test_date: data.data.test_date,
                states: data.data.states.map((state) => state.id),
                status: data.data.status,
                careers: data.data.careers.map((career) => career.id),
                occupations: data.data.occupations.map(
                    (occupation) => occupation.id
                ),
                vacancies: data.data.vacancies,
                minimum_salary: data.data.minimum_salary,
                maximum_salary: data.data.maximum_salary,
                tax_value: data.data.tax_value,
                taf: data.data.taf,
                discursive_writing: data.data.discursive_writing,
                proof_of_titles: data.data.proof_of_titles,
                
            });
        }
    }, [data]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setParams({
                ...params,
                url_notice: file,
            });
            setFileName(file.name);
        }
    };

    const onSubmit = () => {
        if (!params.url_notice) {
            toast.error("Adicione o edital");
            return;
        }

        if (!params.year || params.year === 0) {
            toast.error("Preencha o ano");
            return;
        }
        if (!params.notice_number || params.notice_number.trim() === "") {
            toast.error("Preencha o número do edital");
            return;
        }
        if (!params.level || params.level.trim() === "") {
            toast.error("Preencha o nível");
            return;
        }
        if (!params.institution_id || params.institution_id === 0) {
            toast.error("Selecione a instituição");
            return;
        }
        if (!params.examining_board_id || params.examining_board_id === 0) {
            toast.error("Selecione a banca");
            return;
        }

        const formData = new FormData();

        formData.append("id", params.id.toString());
        formData.append("name", params.name || "");

        if (params.url_notice instanceof File) {
            formData.append("url_notice", params.url_notice);
        }

        formData.append("year", params.year.toString());
        formData.append("notice_number", params.notice_number);
        formData.append("level", params.level);
        formData.append("institution_id", params.institution_id.toString());
        formData.append(
            "examining_board_id",
            params.examining_board_id.toString()
        );
        formData.append(
            "registration_date_start",
            params.registration_date_start
        );
        formData.append("registration_date_end", params.registration_date_end);
        formData.append("test_date", params.test_date);
        formData.append("vacancies", params.vacancies.toString());
        formData.append("minimum_salary", params.minimum_salary.toString());
        formData.append("maximum_salary", params.maximum_salary.toString());
        formData.append("tax_value", params.tax_value.toString());
        formData.append("taf", params.taf ? "1" : "0");
        formData.append(
            "discursive_writing",
            params.discursive_writing ? "1" : "0"
        );
        formData.append("proof_of_titles", params.proof_of_titles ? "1" : "0");
        formData.append("status", params.status.toString());

        for (let i = 0; i < params.states.length; i++) {
            formData.append("states[]", params.states[i].toString());
        }
        for (let i = 0; i < params.careers.length; i++) {
            formData.append("careers[]", params.careers[i].toString());
        }
        for (let i = 0; i < params.occupations.length; i++) {
            formData.append("occupations[]", params.occupations[i].toString());
        }
        formData.append("seo_pages", JSON.stringify(seoPages));

        for (let i = 0; i < exams.length; i++) {
            const { title, url_exam } = exams[i];
            let position = i + 1;
            formData.append(`exam_${position}`, title);
        
            if (url_exam instanceof File) {
                formData.append(`url_exam_${position}`, url_exam);
            }
            else if (url_exam !== null && typeof url_exam === 'string') {
                formData.append(`url_exam_${position}`, url_exam);
            }
        }

        mutate.mutate(formData);
    };

    const mutate = useMutation(
        (data: FormData) => {
            return storePublicTender(data);
        },
        {
            onSettled: (response) => {
                if (response?.data?.success) {
                    toast.success("Salvo com sucesso!");

                    setTimeout(() => {
                        window.location.href = "/public-tenders";
                    }, 80000);
                } else {
                    toast.error("Erro ao salvar o concurso");
                }
            },
            onError: (error) => {
                toast.error("Erro ao salvar o concurso");
            },
        }
    );

    const getYears = () => {
        let years = [];
        let actualYear = new Date().getFullYear();
        for (let i = actualYear; i >= actualYear - 20; i--) {
            years.push(i);
        }

        return years;
    };

    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages,
        });
    }, [seoPages]);

    return (
        <>
            <Formik
                initialValues={{ name: "", acronym: "" }}
                onSubmit={() => onSubmit()}
            >
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a
                                href="/public-tenders"
                                className={styles.btnReturn}
                            >
                                <Arrow /> Voltar
                            </a>
                            <h1>
                                {params.id ? "Editar" : "Cadastrar"} Concurso
                            </h1>
                        </div>
                        {isLoading ? (
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : (
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>
                                        Informe os dados do concurso
                                    </p>
                                    <div className={styles.groupData}>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="url_notice">
                                                    Edital
                                                </label>
                                                <div
                                                    className={styles.fileInput}
                                                >
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        onChange={(e) => {
                                                            handleFileChange(e);
                                                        }}
                                                        className={styles.input}
                                                        type="file"
                                                        name="url_notice"
                                                        id="url_notice"
                                                    />
                                                    {fileName ? (
                                                        <span>
                                                            {" "}
                                                            <Upload />{" "}
                                                            <p>{fileName}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Upload /> <p>Faça o
                                                            upload do edital</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {typeof params.url_notice ===
                                                "string" && (
                                                <div
                                                    className={
                                                        styles.urlNoticeContainer
                                                    }
                                                >
                                                    Arquivo:{" "}
                                                    <a
                                                        href={params.url_notice}
                                                        className={
                                                            styles.urlNotice
                                                        }
                                                    >
                                                        {params.url_notice}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="notice_number">
                                                    Número Edital
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    value={params.notice_number}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    className={styles.input}
                                                    type="text"
                                                    name="notice_number"
                                                    id="notice_number"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="examining_board_id">
                                                    Banca Examinadora
                                                </label>
                                                <Select
                                                    name="examining_board_id"
                                                    styles={stylesSelect}
                                                    defaultValue={{
                                                        value: data?.data
                                                            .examining_board_id,
                                                        label: examiningBoards?.data.examiningBoards.find(
                                                            (examiningBoard) =>
                                                                examiningBoard.id ===
                                                                data?.data
                                                                    .examining_board_id
                                                        )?.name,
                                                    }}
                                                    options={examiningBoards?.data.examiningBoards.map(
                                                        (examiningBoard) => ({
                                                            value: examiningBoard.id,
                                                            label: examiningBoard.name,
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            examining_board_id:
                                                                selectedOption?.value ??
                                                                0,
                                                        });
                                                    }}
                                                    placeholder="Selecione a banca..."
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="institution_id">
                                                    Instituição
                                                </label>
                                                <Select
                                                    defaultValue={{
                                                        value: data?.data
                                                            .institution_id,
                                                        label: institutions?.data.institutions.find(
                                                            (institution) =>
                                                                institution.id ===
                                                                data?.data
                                                                    .institution_id
                                                        )?.name,
                                                    }}
                                                    name="institution_id"
                                                    styles={stylesSelect}
                                                    options={institutions?.data.institutions.map(
                                                        (institution) => ({
                                                            value: institution.id,
                                                            label: institution.name,
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            institution_id:
                                                                selectedOption?.value ??
                                                                0,
                                                        });
                                                    }}
                                                    placeholder="Selecione a instituição..."
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">
                                                    Nome
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.name ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="year">
                                                    Ano
                                                </label>
                                                <Select
                                                    defaultValue={{
                                                        value: data?.data.year,
                                                        label: data?.data.year.toString(),
                                                    }}
                                                    name="year"
                                                    styles={stylesSelect}
                                                    options={getYears().map(
                                                        (year) => ({
                                                            value: year,
                                                            label: year.toString(),
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            year:
                                                                selectedOption?.value ??
                                                                0,
                                                        });
                                                    }}
                                                    placeholder="Selecione o ano..."
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="level">
                                                    Nível
                                                </label>
                                                <Select
                                                    defaultValue={{
                                                        value: data?.data.level,
                                                        label: data?.data.level,
                                                    }}
                                                    name="level"
                                                    styles={stylesSelect}
                                                    options={[
                                                        { value: "fundamental", label: "Fundamental" },
                                                        { value: "médio", label: "Médio" },
                                                        { value: "superior", label: "Superior" },
                                                    ]}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            level:
                                                                selectedOption?.value ??
                                                                "",
                                                        });
                                                    }}
                                                    placeholder="Selecione o nível..."
                                                />

                                               
                                                {/* <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.level ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="text"
                                                    name="level"
                                                    id="level"
                                                /> */}
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="registration_date_start">
                                                    Data de inscrição
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.registration_date_start
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="registration_date_start"
                                                    id="registration_date_start"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="registration_date_end">
                                                    Data de encerramento
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.registration_date_end
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="registration_date_end"
                                                    id="registration_date_end"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="test_date">
                                                    Data da prova
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.test_date}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="test_date"
                                                    id="test_date"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="vacancies">
                                                    Vagas
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.vacancies}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="number"
                                                    name="vacancies"
                                                    id="vacancies"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="minimum_salary">
                                                    Salário mínimo
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.minimum_salary
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="number"
                                                    name="minimum_salary"
                                                    id="minimum_salary"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="maximum_salary">
                                                    Salário máximo
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.maximum_salary
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="number"
                                                    name="maximum_salary"
                                                    id="maximum_salary"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="tax_value">
                                                    Valor da taxa
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.tax_value}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="number"
                                                    name="tax_value"
                                                    id="tax_value"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.groupInput}>
                                            <label htmlFor="">Estados</label>
                                            <Select
                                                defaultValue={data?.data.states.map(
                                                    (state) => ({
                                                        value: state.id,
                                                        label: state.name,
                                                    })
                                                )}
                                                styles={stylesSelect}
                                                options={states?.data.states.map(
                                                    (state) => ({
                                                        value: state.id,
                                                        label: state.name,
                                                    })
                                                )}
                                                placeholder="Selecione os estados..."
                                                onChange={(selectedOptions) => {
                                                    setParams({
                                                        ...params,
                                                        states: selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        ),
                                                    });
                                                }}
                                                isMulti
                                            />
                                        </div>
                                        <div className={styles.groupInput}>
                                            <label htmlFor="">Carreiras</label>
                                            <Select
                                                defaultValue={data?.data.careers.map(
                                                    (career) => ({
                                                        value: career.id,
                                                        label: career.name,
                                                    })
                                                )}
                                                styles={stylesSelect}
                                                options={careers?.data.careers.map(
                                                    (career) => ({
                                                        value: career.id,
                                                        label: career.name,
                                                    })
                                                )}
                                                onChange={(selectedOptions) => {
                                                    setParams({
                                                        ...params,
                                                        careers:
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            ),
                                                    });
                                                }}
                                                placeholder="Selecione as carreiras..."
                                                isMulti
                                            />
                                        </div>
                                        <div className={styles.groupInput}>
                                            <label htmlFor="">Cargos</label>
                                            <Select
                                                defaultValue={data?.data.occupations.map(
                                                    (occupation) => ({
                                                        value: occupation.id,
                                                        label: occupation.name,
                                                    })
                                                )}
                                                styles={stylesSelect}
                                                options={occupations?.data.occupations.map(
                                                    (occupation) => ({
                                                        value: occupation.id,
                                                        label: occupation.name,
                                                    })
                                                )}
                                                onChange={(selectedOptions) => {
                                                    setParams({
                                                        ...params,
                                                        occupations:
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            ),
                                                    });
                                                }}
                                                placeholder="Selecione os cargos..."
                                                isMulti
                                            />
                                        </div>
                                        <div className={styles.alignCheckbox}>
                                            <div className={styles.alignInput}>
                                                <div
                                                    className={
                                                        styles.groupInput
                                                    }
                                                >
                                                    <label htmlFor="taf">
                                                        Teste de Aptidão Física
                                                    </label>
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        className={styles.input}
                                                        type="checkbox"
                                                        checked={params.taf}
                                                        onChange={(e) =>
                                                            setParams({
                                                                ...params,
                                                                taf: e.target
                                                                    .checked,
                                                            })
                                                        }
                                                        name="taf"
                                                        id="taf"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.alignInput}>
                                                <div
                                                    className={
                                                        styles.groupInput
                                                    }
                                                >
                                                    <label htmlFor="discursive_writing">
                                                        Redação Discursiva
                                                    </label>
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        className={styles.input}
                                                        type="checkbox"
                                                        checked={
                                                            params.discursive_writing
                                                        }
                                                        onChange={(e) =>
                                                            setParams({
                                                                ...params,
                                                                discursive_writing:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                        name="discursive_writing"
                                                        id="discursive_writing"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.alignInput}>
                                                <div
                                                    className={
                                                        styles.groupInput
                                                    }
                                                >
                                                    <label htmlFor="proof_of_titles">
                                                        Prova de títulos
                                                    </label>
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        className={styles.input}
                                                        type="checkbox"
                                                        checked={
                                                            params.proof_of_titles
                                                        }
                                                        onChange={(e) =>
                                                            setParams({
                                                                ...params,
                                                                proof_of_titles:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                        name="proof_of_titles"
                                                        id="proof_of_titles"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>
                                        Informe as provas do concurso
                                    </p>
                                    <Exam exams={exams} setExams={setExams} publicTenderId={parseInt(queryParams?.id ?? "0")}/>
                                </div>

                                <div className={styles.box}>
                                    <Seo
                                        entity_id={queryParams?.id ?? null}
                                        type={TypeSeo.PUBLICTENDER}
                                        seoPages={seoPages}
                                        setSeoPages={setSeoPages}
                                    />
                                </div>

                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button
                                            disabled={mutate.isLoading}
                                            width="175px"
                                            height="50px"
                                            type="submit"
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreatePublicTender;
