import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IPostExam } from "../../ts/interfaces/Exam/interface";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { deleteExam, getExams } from "../../services/services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../services/queryClient";

interface ExamProps {
    exams: IPostExam[];
    setExams: (data: IPostExam[]) => void;
    publicTenderId: number;
}

const Exam: React.FC<ExamProps> = ({ exams, setExams, publicTenderId }) => {
    const [fileName, setFileName] = useState<string[]>([]);
    const { data, isLoading } = useQuery<{
        data: IPostExam[];
    }>(["exam", publicTenderId], () => getExams(publicTenderId), {
        staleTime: Infinity,
        enabled: !!publicTenderId,
    });

    React.useEffect(() => {
        if (data) {
            setExams(data.data);
        }
    }, [data]);

    const adicionarProva = () => {
        setExams([...exams, { title: "", url_exam: null }]);
    };

    const handletitleChange = (index: number, value: string) => {
        const newProvas = [...exams];
        newProvas[index].title = value;
        setExams(newProvas);
    };

    const handleFileChange = (index: number, file: File | null) => {
        const newProvas = [...exams];
        newProvas[index].url_exam = file;
        setExams(newProvas);
        setFileName([...fileName, file?.name ?? ""]);
    };

    const mutateDelete = useMutation(
        (index: number) => {
            console.log("INDEX DO MUTATE: ", index);
            return deleteExam(parseInt(exams[index].id ?? "0"));
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["exam", publicTenderId]);
                toast.success("Deletado com sucesso");
            },
            onError: () => {
                toast.error("Erro ao deletar o concurso");
            },
        }
    );

    const handleDelete = (index: number) => {
        if (exams[index].id) {
            console.log("PASSOU AQUI: ", index);
            console.log(exams)
            mutateDelete.mutate(index);
        } else {
            setExams(exams.filter((_, i) => i !== index));
        }
    };

    console.log(fileName);
    return (
        <section>
            <div className={styles.groupData}>
                {exams.map((exam, index) => (
                    <div className={styles.course} key={index}>
                        <p className={styles.examNumber}>Prova {index + 1}</p>
                        <div className={styles.groupInput}>
                            <label>Título</label>
                            <input
                                type="text"
                                value={exam.title}
                                onChange={(e) =>
                                    handletitleChange(index, e.target.value)
                                }
                            />
                        </div>
                        <div className={styles.groupInput}>
                            <label>Prova</label>
                            <div className={styles.fileInput}>
                                <input
                                    type="file"
                                    className={styles.input}
                                    onChange={(e) =>
                                        handleFileChange(
                                            index,
                                            e.target.files
                                                ? e.target.files[0]
                                                : null
                                        )
                                    }
                                />
                                {fileName[index] ? (
                                    <span>
                                        {" "}
                                        <Upload /> <p>{fileName[index]}</p> 
                                    </span>
                                ) : (
                                    <span>
                                        <Upload /> <p>Faça o upload da prova</p>
                                    </span>
                                )}
                            </div>
                            {exam.url_exam &&
                                typeof exam.url_exam === "string" && (
                                    <div className={styles.urlExamContainer}>
                                        Arquivo:{" "}
                                        <a
                                            href={exam.url_exam}
                                            className={styles.urlExam}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {exam.url_exam}
                                        </a>
                                    </div>
                                )}
                        </div>
                        <span
                            onClick={() => {
                                handleDelete(index);
                            }}
                            className={styles.delete}
                        >
                            Deletar
                        </span>
                    </div>
                ))}
            </div>
            <button
                type="button"
                onClick={adicionarProva}
                className={styles.button}
            >
                Adicionar Prova
            </button>
        </section>
    );
};

export default Exam;
